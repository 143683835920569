import SkeletonFilled from '../../molecules/skeleton/filled/skeleton-filled'
import SkeletonList from '@bluheadless/ui/src/molecules/skeleton/list/skeleton-list'
import { Grid } from '@mui/material'
import { useTheme } from '@mui/system'
import useMediaQuery from '@mui/material/useMediaQuery'

const CategoryPageComponentShimmer = () => {
	const theme = useTheme()
	const mdUp = useMediaQuery(theme.breakpoints.up('md'))

	return (
		<Grid container spacing={2} p={3}>
			<Grid item xs={12}>
				<SkeletonFilled width="100%" height={90} />
			</Grid>
			<Grid item xs={12}>
				<SkeletonFilled width="100%" height={60} />
			</Grid>
			<Grid container spacing={2} p={2}>
				<Grid item xs={12} sm={2}>
					<SkeletonFilled width="100%" height="100%" />
				</Grid>
				<Grid item xs={12} sm={10}>
					<Grid container spacing={0} p={0}>
						<Grid item xs={4} sm={4}>
							<SkeletonFilled width="95%" height={mdUp ? 400 : 200} />
						</Grid>
						<Grid item xs={8} sm={8}>
							<SkeletonList height={20} rows={4} mr={2} />
						</Grid>
					</Grid>
					<Grid container spacing={0} p={0} mt={2}>
						<Grid item xs={4} sm={4}>
							<SkeletonFilled width="95%" height={mdUp ? 400 : 200} />
						</Grid>
						<Grid item xs={8} sm={8}>
							<SkeletonList height={20} rows={4} mr={2} />
						</Grid>
					</Grid>
					<Grid container spacing={0} p={0} mt={2}>
						<Grid item xs={4} sm={4}>
							<SkeletonFilled width="95%" height={mdUp ? 400 : 200} />
						</Grid>
						<Grid item xs={8} sm={8}>
							<SkeletonList height={20} rows={4} mr={2} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default CategoryPageComponentShimmer
