import SkeletonFilled from '../../molecules/skeleton/filled/skeleton-filled'
import SkeletonList from '@bluheadless/ui/src/molecules/skeleton/list/skeleton-list'
import { Grid } from '@mui/material'
import { useTheme } from '@mui/system'
import useMediaQuery from '@mui/material/useMediaQuery'

const ProductPageComponentShimmer = () => {
	const theme = useTheme()
	const mdUp = useMediaQuery(theme.breakpoints.up('md'))

	return (
		<Grid container spacing={2} p={0}>
			<Grid item xs={12} sm={12} style={{ minHeight: mdUp ? 800 : 400 }}>
				<SkeletonFilled width="100%" height="100%" />
			</Grid>
			<Grid item xs={12} sm={12} style={{ minHeight: 110 }}>
				<SkeletonFilled width="100%" height="100%" />
			</Grid>
			<Grid container spacing={2} p={3}>
				<Grid item xs={12} md={6} mt={0} mb={4}>
					<SkeletonList height={20} rows={10} mr={2} />
				</Grid>
				<Grid item xs={12} md={6} mt={0} mb={4}>
					<SkeletonFilled width="100%" height="100%" />
				</Grid>
			</Grid>
		</Grid>
	)
}

export default ProductPageComponentShimmer
